import React, { useEffect, useState, useRef } from 'react'
import Link from 'next/link'
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl'
import Cookies from 'js-cookie'


const BottomTab = ({hide}) => {


    return (
        <ul className={`bottom-tab hide-desktop ${hide ? 'disable' : 'enable'}`}>

            <li
                className={`${location.pathname === '/' ? 'active' : ''}`}
            >
                <Link prefetch={false}
                    href={`/`}
                    as={`/`}
                >
                    
                        <i className={`bx ${location.pathname === '/' ? 'bxs' : 'bx'}-home`} />
                        Home
                    
                </Link>
            </li>
            <li
                className={`${location.pathname.substr(0, '/dispensaries'.length) === '/dispensaries' ? 'active' : ''}`}
            >
                <Link prefetch={false}
                    href={`/[business]`}
                    as={`/dispensaries`}
                >
                    
                        <i className={`bx ${location.pathname.substr(0, '/dispensaries'.length) === '/dispensaries' ? 'bxs' : 'bx'}-store`} />
                        {/* <SVG
                            src={toAbsoluteUrl('')}
                        >

                        </SVG> */}
                        Dispensaries
                    
                </Link>
            </li>
        
            <li
                className={`${location.pathname.substr(0, '/deals'.length) === '/deals' ? 'active' : ''}`}
            >
                <Link prefetch={false}
                    href={`${Cookies.get('REGION') ? '/deals/[state]' : '/deals'}`}
                    as={`${Cookies.get('REGION') ? '/deals/'+Cookies.get('REGION').toLowerCase() : '/deals'}`}
                >
                    
                        {/* <SVG
                            src={toAbsoluteUrl('')}
                        >

                        </SVG> */}
                        {console.log(Cookies.get('REGION'))}
                        <i className={`bx ${location.pathname.substr(0, '/deals'.length) === '/deals' ? 'bxs' : 'bx'}-purchase-tag`} />
                        Deals
                    
                </Link>
            </li>

            

            <li
                className={`${location.pathname.substr(0, '/map'.length) === '/map' ? 'active' : ''}`}
            >
                <Link prefetch={false}
                    href={'/map'}
                >
                    
                        {/* <SVG
                            src={toAbsoluteUrl('')}
                        >

                        </SVG> */}
                        <i className={`bx ${location.pathname.substr(0, '/map'.length) === '/map' ? 'bxs' : 'bx'}-map-alt`} />
                        Map
                    
                </Link>
            </li>

            <li
                className={`${location.pathname.substr(0, '/products'.length) === '/products' ? 'active' : ''}`}
            >
                <Link prefetch={false}
                    href={'/products'}
                >
                    
                        {/* <SVG
                            src={toAbsoluteUrl('')}
                        >

                        </SVG> */}
                        <i className={`bx ${location.pathname.substr(0, '/products'.length) === '/products' ? 'bxs' : 'bx'}-basket`} />
                        Products
                    
                </Link>
            </li>
        </ul>
    )

}

export default BottomTab   