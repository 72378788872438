import React, { Component } from 'react';
import Link from 'next/link';
import Image from 'next/image';

class BreadcumbLinks extends Component {
    render() {
        let { homePageUrl, homePageText, activePageText, middlePages, lightText, disableHomePage, image } = this.props;
        return (
            <ul className={`breadc ${lightText ? 'white-text' : ''} `}>
                {
                    !disableHomePage && <li>
                        <Link prefetch={false} href={homePageUrl}> 
                            {homePageText}
                        </Link>
                    </li>
                }
                {
                    middlePages && middlePages.map((x, i)=>
                        <li key={i}>
                            <Link style={{display: 'flex', alignItems: 'center'}} prefetch={false} href={x.href} as={x.as} >
                                
                                    {
                                        x.image && 
                                        <div style={{
                                            width:'32px',
                                            height:'32px',
                                            borderRadius:'100%',
                                            marginRight:'10px'
                                        }}>
                                            <Image 
                                                alt={'Breadcumb miniature image'}
                                                src={x.image.replace('.jpg','').replace('.png','')}
                                                quality='75'
                                                width={32}
                                                height={32}        
                                            /> 
                                        </div>
                                     
                                    }
                                    <span className='d-flex flex-column'>
                                        <span>{x.label}</span>
                                        {
                                            this.props.small && <small style={{marginTop:'-5px', fontSize:'12px'}}>{this.props.small.toLowerCase()}</small>
                                        }
                                    </span>
                                
                            </Link>
                        </li>
                    )
                }
                {
                    activePageText && <li>{activePageText}</li>
                }
            </ul>
        );
    }
}

export default BreadcumbLinks;